@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/colors.scss';

.user_order_create {
  @include container;
  display: flex;
  flex-direction: column;
}

.user_order_create__content {
  display: flex;
  flex-direction: column;
  
  margin: auto;
}

.user_order_create__footer {
  @include respond-to-base {
    margin-top: 40px;
  }

  @include respond-to-sm {
    margin-top: 60px;
    padding: 10px;
  }
}

@import '../../../../styles/mixins.scss';
@import '../../../../styles/colors';

.user_dashboard__page {
  @include container;
  display: flex;
  flex-direction: column;
  

  @include respond-to-base {
    padding-top: 14px;
  }

  @include respond-to-sm {
    padding-top: 21px;
  }
}

.user_dashboard__tabs {
  display: flex;

  @include respond-to-base {
    justify-content: space-between
  }

  @include respond-to-sm {
    justify-content: flex-start
  }
}

.user_dashboard__tab {
  @include button-reset;
  position: relative;

  display: flex;
  align-items: center;
  padding: 12px 20px 12px 60px;

  color: $black-opacity-60;

  background-color: $grey-1;
  border-radius: 12px 12px 0 0;
  border-top: 1px solid $grey-3;
  border-right: 1px solid $grey-3;
  border-left: 1px solid $grey-3;

  transition: all 0.18s ease;

  cursor: pointer;
  z-index: 0;

  @include respond-to-base {
    top: 10px;
    width: 205px;
    height: 64px;
    font-size: 14px;
    font-weight: 500;
  }

  @include respond-to-sm {
    top: 6px;
    width: 190px;
    height: 60px;
    font-size: 16px;
  }

  &-active {
    position: relative;
    height: 60px;
    color: $black-solid;
    background: $white-solid;
    transform: translateY(-5px);
    z-index: 3;

    cursor: auto;

    &::after {
      content: "";
      position: absolute;
      top: 19px;
      height: 40px;
      background: transparent;
      border-bottom-left-radius: 12px;
      border-bottom: 1px solid $grey-3;
      box-shadow: 
      0 20px 0 0 $white-solid; 

      @include respond-to-base {
        left: 183px;
        width: 10px;
      }

      @include respond-to-sm {
        left: 188px;
        width: 40px;
      }
    }

    &:last-of-type {
      &::after {
        @include respond-to-base {
          width: 0;
          height: 0;
        }

        @include respond-to-sm {
          left: 188px;
          height: 40px;
          width: 40px;
        }
      }
    }

    &:not(:first-of-type) {
      &::before {
        content: "";
        position: absolute;
        top: 19px;
        left: -39px;
        height: 40px;
        width: 40px;
        background: transparent;
        border-bottom-right-radius: 12px;
        border-bottom: 1px solid $grey-3;
        box-shadow: 
        0 20px 0 0 $white-solid, 
      }
    }
  }

  &:not(:first-of-type) {
    @include respond-to-base {
      left: 0px;
    }
    
    @include respond-to-sm {
      left: -5px;
    }
  }
}

.user_dashboard__tab_current,
.user_dashboard__tab_history {
  display: inline-block;
  &::before {
    @include pseudo-icon;
    top: 18px;
    left: 27px;
  }
}

.user_dashboard__tab_current {
  &::before {
    background-image: url(icon-current.svg);
    filter: brightness(0) saturate(100%) invert(10%) opacity(50%);
  }

  &-active {
    &::before {
      filter: none;
    }
  }
}

.user_dashboard__tab_history {
  &::before {
    background-image: url(icon-history.svg);
    filter: brightness(0) saturate(100%) invert(10%) opacity(50%);
  }

  &-active {
    &::before {
      filter: none;
    }
  }
}

.user_dashboard__content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $grey-3;
  background: $white-solid;
  z-index: 1;

  @include respond-to-base {
    height: auto;
    padding: 16px;
    border-radius: 0 0 12px 12px;

  }

  @include respond-to-sm {
    height: 750px;
    padding: 24px;
    border-radius: 0 12px 12px 12px;
  }
}

.user_dashboard__content {
  display: flex;
  height: 100%;
}

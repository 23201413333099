.button-standard {
  padding: 24px 40px 24px 40px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 12px;

  font-family: Inter, sans-serif;
  letter-spacing: -1%;
  font-size: 18px;
  font-weight: 500;
  border: none;

  transition: background-color 0.3s ease, transform 0.2s ease;

  cursor: pointer;

  &:hover {
    background: rgba(18, 18, 18, 0.6);
    //transform: scale(1.02); /* Optional: slight scaling effect */
  }

  &:active {
    background-color: #000000;
    //transform: scale(0.98); /* Optional: slight "pressed" effect */
  }
}

@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';
@import '../../../../../../styles/breakpoints.scss';

.user_order_create__address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include respond-to-base {
    gap: 0;
    margin-bottom: 20px;
  }

  @include respond-to-sm {
    gap: 24px;
    margin-bottom: 24px;
  }

  .user_order_create__address__label_wrapper {
    display: flex;
    align-items: center;
    background: #A3C3B5;
    border-radius: 30px;

    @include respond-to-base {
      padding: 6px 18px;
      margin-top: 32px;
      margin-bottom: 18px;
      font-size: 13px;
    }

    @include respond-to-sm {
      margin-top: 48px;
      margin-bottom: 0;
      padding: 8px 18px;
    }

    .user_order_create__address__label {
      height: 19px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: $white-solid;

      @include respond-to-base {
        font-size: 13px;
      }
  
      @include respond-to-sm {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .user_order_create__address__title_big {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.02em;
    color: $black-solid;

    @include respond-to-base {
      max-width: 370px;
      margin-bottom: 18px;
      font-size: 29px;
    }

    @include respond-to-sm {
      max-width: 800px;
      margin-bottom: 0;
      font-size: 60px;
      line-height: 73px;
    }
  }

  .user_order_create__address__title_small {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.02em;
    color: $black-solid;

    @include respond-to-base {
      margin-bottom: 40px;
      font-size: 18px;
    }

    @include respond-to-sm {
      margin-bottom: 0;
      font-size: 26px;
      line-height: 31px;
    }
  }

  .user_order_create__address__address {
    display: flex;

    @include respond-to-base {
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }

    @include respond-to-sm {
      flex-direction: row;
      gap: 24px;
      margin-bottom: 0;
    }

    .user_order_create__address__address__block {
      .user_order_create__address__address__block__label {
        margin-bottom: 10px;
      }
    }
  }
}

.user_order_create__address__address__block__autocomplete {
  position: relative;

  &::after {
    @include pseudo-icon;
    background-image: url(icon_arrow_down.svg);

    @include respond-to-base {
      top: 17px;
      left: 327px;
    }

    @include respond-to-sm {
      top: 22px;
      left: 460px;
    }
  }

  &-complete {
    &::after {
      background-image: url(icon_checkmark.svg);
    }
  }

  &-incomplete {
    &::after {
      background-image: url(icon_alert.svg);
    }
  }
}

.user_order_create__address__ship_details_button {
  @include button-standard;

  @include respond-to-base {
    margin-top: 28px;
  }

  @include respond-to-sm {
    margin-top: 0;
  }
}

@import "../../../../styles/mixins.scss";
@import "../../../../styles/colors.scss";

.user_orders__section {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

.user_orders__no_history {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 18px;
    width: 100%;
  }

  .user_orders__no_history_image {
    @include respond-to-base {
      width: 240px;
      height: 240px;
    }
  
    @include respond-to-sm {
      width: 300px;
      height: 300px;
    }
  }
  
  .user_orders__no_history_title {
    margin: 0;
    padding: 0;
    font-weight: 600;
    line-height: 80%;

    @include respond-to-base {
      font-size: 24px;
    }
  
    @include respond-to-sm {
      font-size: 48px;
    }
  }
  
  .user_orders__no_history_hint {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;

    @include respond-to-base {
      max-width: 348px;
    }
  
    @include respond-to-sm {
      max-width: 476px;
    }
  }
  
  .user_orders__no_history_button {
    @include button-standard;
  
    @include respond-to-base {
      width: 338px;
    }
  
    @include respond-to-sm {
      width: 438px;
    }
  }
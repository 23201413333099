@import '../../../../styles/mixins.scss';
@import "../../../../styles/colors.scss";

.driver_route_create__section {
	display: flex;
  flex-direction: column;
  align-self: flex-start;
	justify-content: flex-start;
  gap: 18px;
	width: 100%;
	margin-top: 48px;
}

.driver_route_create__heading {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 14px;
}

.driver_route_create__title {
	margin: 0;
	padding: 0;
	font-size: 18px;
	font-weight: 600;
}

.driver_route_create__hint {
	margin: 0;
	padding: 0;
	font-size: 16px;
	font-weight: 400;
}

.driver_route_create__content {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	padding: 24px;

	border-radius: 12px;
	border: 1px solid $grey-3;
}

.driver_route_create__address_wrapper {
	display: flex;
	justify-content: space-between;
}

.driver_route_create__address_label {
	font-size: 14px;
	font-weight: 500;
}

.driver_route_create__date_time_wrapper {
	display: flex;
	flex-direction: column;
	align-content: space-between;
	gap: 16px;
}

.driver_route_create__date_time_start,
.driver_route_create__date_time_end {
	display: flex;
	justify-content: space-between;
}

.driver_route_create__date_time_start_date_label,
.driver_route_create__date_time_start_time_label,
.driver_route_create__date_time_end_date_label,
.driver_route_create__date_time_end_time_label {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 480px;

	font-size: 14px;
	font-weight: 500;
}

.driver_route_create__date_time_start_date_input,
.driver_route_create__date_time_start_time_input,
.driver_route_create__date_time_end_date_input,
.driver_route_create__date_time_end_time_input {
	@include input-standard;
	color: $black-opacity-60;

	&:valid {
		color: $black-solid;
	}
}

.driver_route_create__buffer_label {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 480px;

	font-size: 14px;
	font-weight: 500;
}

.driver_route_create__buffer_wrapper {
	display: flex;
}

.driver_route_create__buffer_info_icon {
	position: relative;
  display: block;

  &::before {
    @include pseudo-icon;
		top: 36px;
    left: -238px;
    background-image: url(icon-info.svg);
  }
}

.driver_route_create__buffer_info_tooltip {
	position: absolute;
	top: -24px;
	left: -239px;

  visibility: hidden;
  opacity: 0;

  padding: 14px 16px 14px 16px;

  font-size: 12px;
  font-weight: 500;

  background-color: $green-3;
  border-radius: 12px;
  box-shadow: 0px 220px 88px rgba(86, 87, 103, 0.01), 0px 124px 74px rgba(86, 87, 103, 0.05), 0px 55px 55px rgba(86, 87, 103, 0.09), 0px 14px 30px rgba(86, 87, 103, 0.1);

  transition: opacity, 0.3s;
  z-index: 1;
}

.driver_route_create__buffer_info_icon:hover + .driver_route_create__buffer_info_tooltip {
	visibility: visible;
  opacity: 1;
}

.driver_route_create__buffer_input {
	@include input-standard;
}

.driver_route_create__buffer_info {
	position: relative;
}

.driver_route_create__submit {
	@include button-standard;
	align-self: center;

	@include respond-to-sm {
		width: 270px;
	}
}

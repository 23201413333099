@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';

.order_create__footer__info {
  display: flex;
  justify-content: space-between;
}


.order_create__footer__eco_friendly,
.order_create__footer__budget_friendly,
.order_create__footer__on_demand,
.order_create__footer__safety_first {
  @include respond-to-base {
    width: 82px;
    padding: 16px 10px 16px 10px;
    background-color: $white-opacity-50;
    border-radius: 25px;
  }

  @include respond-to-sm {
    width: auto;
    max-width: 238px;
    padding: 0;
    background-color: transparent;
  }

  .order_create__footer__eco_friendly__logo,
  .order_create__footer__budget_friendly__logo,
  .order_create__footer__on_demand__logo,
  .order_create__footer__safety_first__logo {
    display: flex;

    @include respond-to-base {
      justify-content: center;
    }

    @include respond-to-sm {
      justify-content: flex-start;
    }

    .order_create__footer__eco_friendly__logo__span,
    .order_create__footer__budget_friendly__logo__span,
    .order_create__footer__on_demand__logo__span,
    .order_create__footer__safety_first__logo__span {
      @include respond-to-base {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        width: 24px;
        margin-bottom: 5px;
      }

      @include respond-to-sm {
        width: 60px;
        height: 60px;
        margin-bottom: 18px;
        border-radius: 25px;
        background-color: $white-opacity-50;
      }
    }
  }

  .order_create__footer__eco_friendly__title,
  .order_create__footer__budget_friendly__title,
  .order_create__footer__on_demand__title,
  .order_create__footer__safety_first__title {
    font-style: normal;

    @include respond-to-base {
      font-size: 10px;
      font-weight: 500;
      line-height: 12px;
      text-align: center;
    }

    @include respond-to-sm {
      margin-bottom: 14px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: left;
    }
  }

  .order_create__footer__eco_friendly__text,
  .order_create__footer__budget_friendly__text,
  .order_create__footer__on_demand__text,
  .order_create__footer__safety_first__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.6);
  }
}

.order_create__footer__border {
  margin-left: 87px;
  width: 44px;
  border-left: 1px solid #C2C6D3;
}

@import '../../../styles/mixins.scss';
@import '../../../styles/colors';

.user_dashboard__section {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 18px;

  &-no_order {
    align-self: center;
  }
}

.user_dashboard__heading {
  display: flex;
  gap: 14px;

  @include respond-to-base {
    justify-content: space-between;
  }

  @include respond-to-sm {
    justify-content: flex-start;
  }
}

.user_dashboard__title {
  margin: 0;
  padding: 0;
  font-weight: 500;

  @include respond-to-base {
    font-size: 24px;
  }

  @include respond-to-sm {
    font-size: 36px;
  }
}

.user_dashboard__button-actions {
  @include button-reset;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  cursor: pointer;

  @include respond-to-base {
    width: 32px;
    height: 32px;
    background-image: url(icon-actions-mobile.svg);
  }

  @include respond-to-sm {
    width: 44px;
    height: 44px;
    background-image: url(icon-actions.svg);
  }

  &:hover {
    opacity: 70%;
  }
}

.user_dashboard__current {
  display: flex;
  gap: 24px;

  @include respond-to-base {
    flex-direction: column;
  }

  @include respond-to-sm {
    flex-direction: row;
  }
}

.user_dashboard__current_order {
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  @include respond-to-base {
    width: 338px;
  }

  @include respond-to-sm {
    width: 398px;
  }
}

.user_dashboard__current_order_tabs {
  display: flex;
}

.user_dashboard__current_order_tab {
  @include button-link;
  
  padding-top: 0;
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  color: $grey-4;
  border-bottom: 1px solid $grey-2;

  @include respond-to-base {
    width: 170px;
  }

  @include respond-to-sm {
    width: 233px;
  }

  &-active {
    color: $black-solid;
    border-bottom: 1px solid $black-solid;
    cursor: auto;

    &:hover {
      color: $black-solid;
    }
  }
}

.user_dashboard__no_order {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 100%;
}

.user_dashboard__no_order_image {
  @include respond-to-base {
    width: 240px;
    height: 240px;
  }

  @include respond-to-sm {
    width: 300px;
    height: 300px;
  }
}

.user_dashboard__no_order_title {
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 80%;

  @include respond-to-base {
    font-size: 24px;
  }

  @include respond-to-sm {
    font-size: 48px;
  }
}

.user_dashboard__no_order_hint {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;

  @include respond-to-base {
    max-width: 348px;
  }

  @include respond-to-sm {
    max-width: 476px;
  }
}

.user_dashboard__no_order_button {
  @include button-standard;

  @include respond-to-base {
    width: 338px;
  }

  @include respond-to-sm {
    width: 438px;
  }
}
@import '../../styles/mixins.scss';

.map {
  @include respond-to-base {
		height: 310px;
  }

	@include respond-to-sm {
		height: 468px;
	}
}

@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';

.user_order_create__delivery_details__section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid $grey-2;

  @include respond-to-base {
    gap: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
  }

  @include respond-to-sm {
    gap: 26px;
    margin-bottom: 24px;
    padding-top: 24px;
  }
}

.user_order_create__delivery_details__title {
  width: 100%;
  margin: 0;

  font-weight: 600;

  @include respond-to-base {
    font-size: 16px;
  }

  @include respond-to-sm {
    font-size: 20px;
  }
}

.user_order_create__delivery_details__pickup,
.user_order_create__delivery_details__delivery {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: $white-opacity-50;
  border-radius: 12px;

  @include respond-to-base {
    padding: 16px 18px 16px 18px;
  }

  @include respond-to-sm {
    padding: 26px 24px 26px 24px;
  }
}


.user_order_create__delivery_details__pickup_accordion_header,
.user_order_create__delivery_details__delivery_accordion_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user_order_create__delivery_details__pickup_title,
.user_order_create__delivery_details__delivery_title {
  position: relative;
  width: 100%;
  margin: 0;

  font-weight: 600;

  @include respond-to-base {
    margin-bottom: 10px;
    font-size: 16px;
  }

  @include respond-to-sm {
    margin-bottom: 28px;
    font-size: 18px;
  }

  &::after {
    @include pseudo-icon;
    background-image: url(icon-arrow-down.svg);
    transition: transform 0.4s ease-in-out;
    transform: rotate(0deg);

    @include respond-to-base {
      top: -5px;
      left: 309px;
    }

    @include respond-to-sm {
      top: 0;
      left: 948px;
    }
  }

  &-open {
    &::after {
      transform: rotate(180deg);
    }
  }

  &-close {
    &::after {
      transform: rotate(0deg);
    }
  }
}

.user_order_create__delivery_details__pickup_asap,
.user_order_create__delivery_details__pickup_contactless,
.user_order_create__delivery_details__delivery_asap,
.user_order_create__delivery_details__delivery_contactless {
  display: flex;
  width: 48%;
}

.user_order_create__delivery_details__pickup_asap_input,
.user_order_create__delivery_details__pickup_contactless_input,
.user_order_create__delivery_details__delivery_asap_input,
.user_order_create__delivery_details__delivery_contactless_input {
  appearance: none;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.user_order_create__delivery_details__pickup_asap_label,
.user_order_create__delivery_details__pickup_contactless_label,
.user_order_create__delivery_details__delivery_asap_label,
.user_order_create__delivery_details__delivery_contactless_label {
  position: relative;
  display: inline-block;

  padding-left: 40px;
  margin-right: 10px;

  font-size: 18px;
  font-weight: 600;

  cursor: pointer;

  @include respond-to-base {
    font-size: 16px;
  }

  @include respond-to-sm {
    font-size: 18px;
  }

  &::before {
    @include pseudo-icon;
    left: -1px;
    width: 28px;
    height: 28px;
    background-image: url(icon-checkbox-unchecked.svg);

    @include respond-to-base {
      top: -3px;
    }
  
    @include respond-to-sm {
      top: -4px;
    }
  }
}

.user_order_create__delivery_details__pickup_contactless_label,
.user_order_create__delivery_details__delivery_contactless_label {
  vertical-align: middle;
}

.user_order_create__delivery_details__pickup_asap_input:checked + .user_order_create__delivery_details__pickup_asap_label::before,
.user_order_create__delivery_details__pickup_contactless_input:checked + .user_order_create__delivery_details__pickup_contactless_label::before,
.user_order_create__delivery_details__delivery_asap_input:checked + .user_order_create__delivery_details__delivery_asap_label::before,
.user_order_create__delivery_details__delivery_contactless_input:checked + .user_order_create__delivery_details__delivery_contactless_label::before {
  background-image: url(icon-checkbox-checked.svg);
}

.user_order_create__delivery_details__pickup_asap_info,
.user_order_create__delivery_details__pickup_contactless_info,
.user_order_create__delivery_details__delivery_asap_info,
.user_order_create__delivery_details__delivery_contactless_info {
  position: relative;
}

.user_order_create__delivery_details__pickup_asap_info_icon,
.user_order_create__delivery_details__pickup_contactless_info_icon,
.user_order_create__delivery_details__delivery_asap_info_icon,
.user_order_create__delivery_details__delivery_contactless_info_icon {
  position: relative;
  display: block;

  &::before {
    @include pseudo-icon;
    background-image: url(icon-info.svg);
  }
}

.user_order_create__delivery_details__pickup_asap_info_icon, 
.user_order_create__delivery_details__delivery_asap_info_icon {
  &::before {
    @include respond-to-base {
      left: -1px;
      top: -3px;
    };

    @include respond-to-sm {
      left: 0;
      top: -2px;
    }
  }
}

.user_order_create__delivery_details__pickup_contactless_info_icon,
.user_order_create__delivery_details__delivery_contactless_info_icon {
  &::before {
    @include respond-to-base {
      left: 0px;
      top: -3px;
    };

    @include respond-to-sm {
      left: 0px;
      top: -2px;
    }
  }
}

.user_order_create__delivery_details__pickup_asap_info_tooltip,
.user_order_create__delivery_details__pickup_contactless_info_tooltip,
.user_order_create__delivery_details__delivery_asap_info_tooltip,
.user_order_create__delivery_details__delivery_contactless_info_tooltip {
  position: absolute;

  visibility: hidden;
  opacity: 0;

  padding: 14px 16px 14px 16px;

  font-size: 12px;
  font-weight: 500;

  background-color: $green-3;
  border-radius: 12px;
  box-shadow: 0px 220px 88px rgba(86, 87, 103, 0.01), 0px 124px 74px rgba(86, 87, 103, 0.05), 0px 55px 55px rgba(86, 87, 103, 0.09), 0px 14px 30px rgba(86, 87, 103, 0.1);

  transition: opacity, 0.3s;
  z-index: 1;
}

.user_order_create__delivery_details__pickup_asap_info_tooltip,
.user_order_create__delivery_details__delivery_asap_info_tooltip {
  @include respond-to-base {
    top: -102px;
    right: -189px;
  }

  @include respond-to-sm {
    top: -40px;
    right: -461px;
  }
}

.user_order_create__delivery_details__pickup_contactless_info_tooltip,
.user_order_create__delivery_details__delivery_contactless_info_tooltip {
  @include respond-to-base {
    top: -82px;
    left: -189px;
  }

  @include respond-to-sm {
    left: 0;
    top: -60px;
    right: -441px;
  }
}

.user_order_create__delivery_details__pickup_asap_info_icon:hover + .user_order_create__delivery_details__pickup_asap_info_tooltip,
.user_order_create__delivery_details__pickup_contactless_info_icon:hover + .user_order_create__delivery_details__pickup_contactless_info_tooltip,
.user_order_create__delivery_details__delivery_asap_info_icon:hover + .user_order_create__delivery_details__delivery_asap_info_tooltip,
.user_order_create__delivery_details__delivery_contactless_info_icon:hover + .user_order_create__delivery_details__delivery_contactless_info_tooltip {
  visibility: visible;
  opacity: 1;
}

.user_order_create__delivery_details__pickup_accordion-body,
.user_order_create__delivery_details__delivery_accordion-body {
  display: flex;

  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, margin-top 0.4s ease-in-out;

  @include respond-to-base {
    flex-direction: column;
    gap: 20px;
  }

  @include respond-to-sm {
    flex-direction: row;
    gap: 69px;
  }

  &-open {
    max-height: 500px;
    margin-top: 26px;
    opacity: 1;
  }

  &-close {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
  }
}

.user_order_create__delivery_details__pickup_schedule-wrapper,
.user_order_create__delivery_details__delivery_schedule-wrapper {
  @include respond-to-base {
    width: 334px;
    justify-content: flex-start;

    &-visible {
      position: static;
    }

    &-hidden {
      position: absolute;
    }
  }

  @include respond-to-sm {
    width: 438px;
  }
}

.user_order_create__delivery_details__pickup_phone-wrapper,
.user_order_create__delivery_details__delivery_phone-wrapper {
  @include respond-to-base {
    width: 334px;

    &-visible {
      position: static;
    }

    &-hidden {
      position: absolute;
    }
  }

  @include respond-to-sm {
    width: 438px;
  }
}


.user_order_create__delivery_details__pickup_schedule,
.user_order_create__delivery_details__pickup_phone,
.user_order_create__delivery_details__delivery_schedule,
.user_order_create__delivery_details__delivery_phone {
  display: flex;
  flex-direction: column;
  gap: 10px;

  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out;

  &-visible {
    opacity: 1;
    max-height: 500px;
  }

  &-hidden {
    opacity: 0;
    max-height: 0;
  }
}

.user_order_create__delivery_details__pickup_date_input,
.user_order_create__delivery_details__pickup_time_input,
.user_order_create__delivery_details__pickup_phone_input,
.user_order_create__delivery_details__delivery_date_input,
.user_order_create__delivery_details__delivery_time_input,
.user_order_create__delivery_details__delivery_phone_input {
  @include input-standard;

  @include respond-to-base {
    width: 334px;
  }

  @include respond-to-sm {
    width: 438px;
  }
}

.user_order_create__delivery_details__pickup_date_label,
.user_order_create__delivery_details__pickup_time_label,
.user_order_create__delivery_details__pickup_phone_label,
.user_order_create__delivery_details__delivery_date_label,
.user_order_create__delivery_details__delivery_time_label,
.user_order_create__delivery_details__delivery_phone_label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
}

.user_order_create__delivery_details__summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.user_order_create__delivery_details__summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background-color: $white-opacity-50;
  border-radius: 12px;
  padding: 26px 24px 26px 24px;
}

.user_order_create__delivery_details__summary_title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.user_order_create__delivery_details__summary_details {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.user_order_create__rate_section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid $grey-2;

  @include respond-to-base {
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
  }

  @include respond-to-sm {
    flex-direction: row;
    gap: 0;
    padding-top: 24px;
  }
}

.user_order_create__rate_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  @include respond-to-base {
    align-items: center;
    gap: 4px
  }

  @include respond-to-sm {
    align-items: flex-start;
    gap: 10px;
  }
}

.user_order_create__rate_title {
  font-weight: 600;

  @include respond-to-base {
    font-size: 14px;
  }

  @include respond-to-sm {
    font-size: 20px;
  }
}

.user_order_create__rate_amount {
  font-weight: 600;

  @include respond-to-base {
    font-size: 32px;
  }

  @include respond-to-sm {
    font-size: 48px;
  }
}

.user_order_create__rate_button {
  @include button-standard;
  align-self: center;
}

@import '../../../styles/mixins.scss';
@import '../../../styles/colors.scss';


.order_details__section {
	display: flex;
	flex-direction: column;

	@include respond-to-base {
		gap: 20px;
	}

	@include respond-to-sm {
		gap: 24px;
	}
}

.order_details__detail_wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.order_details__detail_title {
	font-size: 16px;
	font-weight: 600;
	margin: 0;

	@include respond-to-base {
		margin-bottom: 2px;
	}

	@include respond-to-sm {
		margin-bottom: 10px;
	}
}

.order_details__detail_data {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
}

.order_details__detail_packages {
	margin: 0;
	padding-left: 20px;
}

.order_details__detail_package {
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 400;
}

.order_details__detail_photos {
	@include reset-list;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.order_details__detail_photo {
  border-radius: 12px;
  object-fit: cover;

	@include respond-to-base {
		width: 105px;
		height: 82px;
	}

	@include respond-to-sm {
		width: 126px;
		height: 98px;
	}
}

.order_details__detail_rate {
	display: flex;
	justify-content: space-between;
	padding-top: 24px;
	border-top: 1px solid $grey-2;
}

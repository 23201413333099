@import "./colors.scss";
@import "./mixins.scss";

body {
  font-family: Inter, sans-serif;
  color: $black-solid;
  background-color: $grey-1;
}

body::before,
body::after {
  content: '';
  position: fixed;
  z-index: -1;

  @include respond-to-base {
    background: linear-gradient(143.7deg, 
      rgba(54, 146, 128, 0.3) 5%, 
      rgba(54, 146, 128, 0.5) 15%, 
      rgba(54, 146, 128, 0.6) 35%, 
      rgba(255, 255, 255, 1) 75%);
    filter: blur(125px);
    width: 330px;
    height: 1318px;
  }

  @include respond-to-md {
    background: linear-gradient(143.7deg, 
      rgba(54, 146, 128, 0.4) 15.28%, 
      rgba(54, 146, 128, 0.7) 77.67%);
    filter: blur(250px);
    width: 662px;
    height: 1318px;
  }
}

body::before {
  top: 0;
  left: -436px;
  transform: rotate(-30deg);

  @include respond-to-base {
    left: -236px;
  }

  @include respond-to-md {
    left: -436px;
  }
}

body::after {
  bottom: 0;
  right: -436px;
  transform: rotate(-30deg);
}

main {
  padding-top: 100px;
}

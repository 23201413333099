@use "../../../styles/mixins" as *;
@use "../../../styles/colors" as *;

.header {
  @include container;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  font-family: Inter, sans-serif;
  color: $black-solid;

  backdrop-filter: blur(25px);

  z-index: 10;

  @include respond-to-base {
    width: 100%;
    padding: 0;
  }

  @include respond-to-sm {
    width: 1280px;
    padding-right: 85px;
    padding-left: 85px;
  }
}

.header__header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #C2C6D3;
  
  background-color: transparent;

  @include respond-to-base {
    width: 100%;
    padding-right: 16px;
    padding-left: 24px;
  }

  @include respond-to-sm {
    padding-right: 0;
    padding-left: 0;
  }
}

.header__header_wrapper__left {
  margin-right: auto;
}


.header__header_wrapper__right-desktop {
  display: flex;
}

.header__header_wrapper__right-mobile {
  position: fixed;
  top: 90px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 90px); 

  background-color: $green-4;

  z-index: 10;
}


.header__navigation-desktop {
  display: flex;
  align-items: center;
}

.header__navigation-mobile {
  display: flex;
  align-items: center;
  width: 100%;
}

.header__navigation__list-desktop {
  @include reset-list;

  display: flex;
  align-items: center;

  gap: 40px;
  margin-right: 40px;
  margin-left: auto;
}

.header__navigation__list-mobile {
  @include reset-list;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header__navigation__list-item_link {
  @include reset-anchor;
}

.header__navigation__list-item-desktop {
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: $black-opacity-60;
  }

  &:active {
    color: $black-solid;
  }
}

.header__navigation__list-item-mobile {
  width: 100%;
  padding: 32px 0;

  font-size: 24px;
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid $grey-2;
}

.header__navigation__login-desktop {
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;

  transition: color 0.2s ease;
}

.header__navigation__list-item_link-login {
  @include reset-anchor;
  display: inline-block;
  padding: 14px 18px;
}

.header__navigation__login-mobile {
  width: 100%;
  margin-bottom: auto;

  font-size: 24px;
  font-weight: 400;
  text-align: center;


}

.header__navigation__list-item-active-desktop {
  position: relative;
  font-weight: 600;

  &:hover {
    cursor: auto;
    color: $black-solid;
  }

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    right: 86px;
    width: 24px;
    height: 24px;
    background-image: url('icon-arrow-right.svg'); 
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.header__navigation__list-item-active-mobile {
  position: relative;
  font-weight: 600;
}

.header__navigation__list-item-arrow-mobile {
  position: relative;
  top: 4px;
  left: -8px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('icon-arrow-right.svg'); 
  background-size: contain;
  background-repeat: no-repeat;
}

.header__navigation__list-item-arrow-desktop {
  position: relative;
  top: 4px;
  left: -8px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('icon-arrow-right.svg'); 
  background-size: contain;
  background-repeat: no-repeat;
}

.header__navigation_button {
  @include button-reset;
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  
  &-open {
    background-image: url(icon-burger.svg);
  }

  &-close {
    background-image: url(icon-cross.svg);
  }
}

.header_logo_link {
  @include reset-anchor;
}

.header__navigation__user {
  @include button-reset;
  display: flex;
  align-items: center;
  gap: 15px;
  border: none;
  cursor: pointer;

  &:hover .header__navigation__user_name   {
    color: $black-opacity-60;
  }

  &:active .header__navigation__user_name  {
    color: $black-solid;
  }
}

.header__navigation__user_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  font-size: 16px;
  font-weight: 500;

  background-color: $white-opacity-65;
}

.header__navigation__user_name {
  position: relative;

  font-size: 16px;
  font-weight: 600;
}

.header__navigation__user_menu {
  position: absolute;
  bottom: -44px;
  right: 80px;
  flex-direction: column;
  width: 227px;
  padding: 7px;

  border-radius: 12px;
  border: 1px solid $grey-2;
  background-color: $white-solid;

  box-shadow: 
    0px 14px 30px 0px rgba(86, 87, 103, 0.1),
    0px 55px 55px 0px rgba(86, 87, 103, 0.09),
    0px 124px 74px 0px rgba(86, 87, 103, 0.05),
    0px 220px 88px 0px rgba(86, 87, 103, 0.01),
    0px 344px 96px 0px rgba(86, 87, 103, 0);
}

.header__navigation__user_menu_wrapper {
  @include reset-list;
  display: flex;
}

.header__navigation__user_menu_item {
  position: relative;
  display: inline-block;
  padding: 18px 105px 19px 52px;

  font-size: 14px;
  font-weight: 500;
  
  border-radius: 12px;

  &:hover {
    background-color: $grey-1;
  }

  &::before {
    @include pseudo-icon;
    top: 17px;
    left: 26px;
    width: 18px;
    height: 18px;
    background-image: url(icon_logout.svg);
  }
}

.header__navigation__user_menu-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header__navigation__user_menu-mobile_wrapper {
  display: flex;
  justify-content: center;
  gap: 14px;
  padding: 32px 0;
  width: 100%;
  border-bottom: 1px solid $grey-2;
}

.header__navigation__user_avatar-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;

  font-size: 12px;
  font-weight: 500;

  background-color: $white-solid;
}

.header__navigation__user_name-mobile {
  font-size: 24px;
  font-weight: 400;
}

.header__navigation__user_menu_item-mobile {
  width: 100%;
  padding: 32px 0;

  font-size: 24px;
  font-weight: 400;
}

@use "../../styles/mixins" as *;
@use "../../styles/colors" as *;

.footer {
  @include container;
  display: flex;
  justify-content: space-between;
  padding-bottom: 22px;
  margin-top: auto;

  @include respond-to-base {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 48px;
    padding-left: 58px;
    padding-right: 58px;
    padding-bottom: 24px;
  }

  @include respond-to-sm {
    flex-direction: row;
    align-items: baseline;
    padding-top: 60px;
  }
}

.footer__copyright,
.footer__documents_privacy,
.footer__documents_agreement {
  @include reset-anchor;
  margin: 0;
  font-weight: 400;
  color: $black-opacity-60;

  @include respond-to-base {
    font-size: 13px;
  }

  @include respond-to-sm {
    font-size: 14px;
  }
}

.footer__documents_privacy,
.footer__documents_agreement,
.footer__socials_item {
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.5;
  }
}

.footer__socials {
  @include reset-list;
  display: flex;
  gap: 24px;
  justify-content: space-around;
  align-items: center;

  @include respond-to-base {
    order: 1;
  }

  @include respond-to-sm {
    order: 2;
  }
}

.footer__socials_item {
  position: relative;
  display: flex;
  width: 24px;
}

.footer__socials_instagram {
  display: block;
  @include pseudo-icon;
  top: -20px;
  background-image: url(icon-instagram.svg);
}

.footer__socials_tiktok {
  display: block;
  @include pseudo-icon;
  top: -20px;
  background-image: url(icon-tiktok.svg);
}

.footer__socials_youtube {
  display: block;
  @include pseudo-icon;
  top: -20px;
  background-image: url(icon-youtube.svg);
}

.footer__socials_facebook {
  display: block;
  @include pseudo-icon;
  top: -20px;
  background-image: url(icon-facebook.svg);
}

.footer__documents {
  display: flex;
  align-items: center;

  @include respond-to-base {
    justify-content: space-between;
    order: 2;
    gap: 14px;
  }

  @include respond-to-sm {
    gap: 75px;
    order: 3;
  }
}

.footer__copyright {
  @include respond-to-base {
    order: 3;
  }

  @include respond-to-sm {
    order: 1;
  }
}
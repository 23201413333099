@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';

.user_order_create__packages__section {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $grey-2;

  @include respond-to-base {
    gap: 20px;
    margin-bottom: 20px;
    padding-top: 20px;
  }

  @include respond-to-sm {
    gap: 24px;
    margin-bottom: 24px;
    padding-top: 24px;
  }

  .user_order_create__packages__heading {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .user_order_create__packages__title {
    font-style: normal;
    font-weight: 600;

    letter-spacing: -0.02em;
    color: $black-solid;

    @include respond-to-base {
      font-size: 16px;
    }

    @include respond-to-sm {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .user_order_create__packages__icons {
    display: grid;

    @include respond-to-base {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 12px;
      column-gap: 12px;
    }

    @include respond-to-sm {
      grid-template-columns: repeat(4, 1fr);
      row-gap: 10px;
      column-gap: 16px;
    }

    .user_order_create__packages__icons__wrapper {
      position: relative;
      display: flex;
      padding: 5px;
      
      background: $white-solid;
      border-radius: 12px;
      border: 1px solid $white-solid;

      cursor: pointer;

      @include respond-to-base {
        gap: 4px;
        width: 179px;
        height: 74px;
      }

      @include respond-to-sm  {
        gap: 10px;
        width: 241px;
        height: 88px;
      }

      &:hover {
        background-color: #D2EAE0;
        border: 1px solid #5FA98A;
        box-shadow: 0px 85px 34px rgba(83, 84, 90, 0.01), 0px 48px 29px rgba(83, 84, 90, 0.05), 0px 21px 21px rgba(83, 84, 90, 0.09), 0px 5px 12px rgba(83, 84, 90, 0.1);
        transition: all 0.2s ease-in-out;
      }

      &-selected {
        @include respond-to-base {
          background-color: #D2EAE0;
          border: 1px solid #5FA98A;
          box-shadow: 0px 85px 34px rgba(83, 84, 90, 0.01), 0px 48px 29px rgba(83, 84, 90, 0.05), 0px 21px 21px rgba(83, 84, 90, 0.09), 0px 5px 12px rgba(83, 84, 90, 0.1);

        @include respond-to-sm {
          background: $white-solid;
          border-radius: 12px;
          border: 1px solid $white-solid;
          box-shadow: none;

          &::after {
            content: "";
            position: absolute;
            top: 33px;
            left: 201px;
            width: 24px;
            height: 24px;
            background-image: url('icon_checkmark.svg');
            background-size: contain;
            background-repeat: no-repeat;
          }

          &:hover {
            background: $white-solid;
            border-radius: 12px;
            border: 1px solid $white-solid;
            box-shadow: none;
          }
        }
      }
    }

      .user_order_create__packages__icons__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $grey-1;
        border-radius: 12px;

        @include respond-to-base {
          width: 58px;
          height: 63px;
        }

        @include respond-to-sm {
          width: 70px;
          height: 76px;
        }
      }

      .user_order_create__packages__icons__title_and_description {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .user_order_create__packages__icons__title {
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.02em;
          color: $black-solid;

          @include respond-to-base {
            font-size: 15px;
          }

          @include respond-to-sm {
            font-size: 16px;
            line-height: 19px;
          }
        }

        .user_order_create__packages__icons__description {
          font-style: normal;
          font-weight: 400;

          letter-spacing: -0.02em;

          color: $black-solid;

          @include respond-to-base {
            font-size: 12px;
          }

          @include respond-to-sm {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }

  .user_order_create__packages__table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $white-opacity-50;
    border-radius: 12px;

    @include respond-to-base {
      padding: 16px;
      width: 370px;
    }

    @include respond-to-sm {
      padding: 26px 24px;
      width: 1024px;
    }



    .user_order_create__packages__table__dimensions {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
  
      padding-top: 10px;

      @include respond-to-base {
        flex-wrap: wrap;
        gap: 14px;
      }

      @include respond-to-sm {
        flex-wrap: nowrap;
        gap: 16px;
      }
    }

    .user_order_create__packages__table__element {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 10px;

      .user_order_create__packages__table__element__header {
        position: relative;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: $black-solid;

        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 24px;
          background-image: url('icon_checkmark.svg');
          background-size: contain;
          background-repeat: no-repeat;

          @include respond-to-base {
            top: 43px;
            left: 120px;
          }

          @include respond-to-sm {
            top: 50px;
            left: 184px;
          }
        }
      }

      .user_order_create__packages__table__element__input {
        background: $white-solid;
        border: 1px solid $grey-2;
        border-radius: 12px;
        font-family: inherit;
        
        @include respond-to-base {
          width: 162px;
          height: 58px;
          padding: 21px 16px 21px 16px;
          font-size: 13px;
        }

        @include respond-to-sm {
          width: 228px;
          height: 70px;
          padding: 25px 24px 26px 24px;
          font-size: 16px;
        }

        &:focus {
          border: 1px solid $black-solid;
          outline: none;
        }
      }
    }
  }
}

.user_order_create__packages__table__title {
  position: relative;
  width: 100%;
  font-style: normal;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: $black-solid;

  @include respond-to-base {
    font-size: 16px;
  }

  @include respond-to-sm {
    font-size: 18px;
  }
}

.user_order_create__packages__table__description {
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: $black-solid;

  @include respond-to-base {
    font-size: 14px;
  }

  @include respond-to-sm {
    font-size: 16px;
  }
}

.user_order_create__packages__table__weight-select {
  align-self: flex-end;
  background: $white-solid;
  border: 1px solid $grey-2;
  border-radius: 12px;
  font-family: inherit;

  appearance: none;

  @include respond-to-base {
    width: 162px;
    height: 58px;
    padding: 21px 16px 21px 16px;
    font-size: 13px;
  }

  @include respond-to-sm {
    width: 228px;
    height: 70px;
    padding: 25px 24px 26px 24px;
    font-size: 16px;
  }

  &:focus {
    border: 1px solid $black-solid;
    outline: none;
  }
}

.user_order_create__packages__table__weight {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include respond-to-sm {
    margin-left: 16px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -16px;
      width: 1px;
      height: 100px;
      background-color: $grey-2;
    }
  }
}

.user_order_create__packages__table__weight_label {
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: $black-solid;

  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-image: url('icon_arrow_down.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @include respond-to-base {
      top: 46px;
      left: 121px;
    }

    @include respond-to-sm {
      top: 50px;
      left: 184px;
    }
  }
}

.user_order_create__packages__add {
  @include button-link;

  position: relative;
  padding-left: 30px;
  color: $black-opacity-60;

  @include respond-to-base {
    font-size: 14px;
    align-self: flex-start;
  }

  @include respond-to-sm {
    font-size: 18px;
    align-self: flex-end;
  }

  &:hover {
    color: $black-opacity-40;
  }

  &::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-image: url('icon-plus.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @include respond-to-base {
      top: -2px;
      left: 2px;
    }

    @include respond-to-sm {
      top: 0;
      left: 0;
    }
  }
}

.user_order_create__packages__table__heading {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  &::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 4px;
    width: 24px;
    height: 24px;
    background-image: url('icon_arrow_down.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 0.4s ease-in-out;
    transform: rotate(0deg);
  }

  &-open {
    &::after {
      transform: rotate(180deg);
    }
  }

  &-close {
    &::after {
      transform: rotate(0deg);
    }
  }
}

.user_order_create__packages__table__accordion {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out, margin-top 0.4s ease-in-out;

  &-open {
    max-height: 500px;
    margin-top: 10px;
    opacity: 1;
  }

  &-close {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
  }
}

.user_order_create__packages__show_rate {
  @include button-standard;
  align-self: center;
}

.user_order_create__packages__title_wrapper {
  display: flex;
  justify-content: space-between;
}

.user_order_create__packages__summary {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border-radius: 12px;
}

.user_order_create__packages__delete {
  @include button-link;

  position: relative;
  align-self: flex-end;
  padding-left: 30px;
  color: $black-opacity-60;

  @include respond-to-base {
    font-size: 14px;
  }

  @include respond-to-sm {
    font-size: 18px;
  }

  &:hover {
    color: $black-opacity-40;
  }

  &::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-image: url('icon-cross.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @include respond-to-base {
      top: -3px;
      left: 2px;
    }

    @include respond-to-sm {
      top: 0;
      left: 0;
    }
  }
}

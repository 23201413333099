$black-solid: #121212;
$black-opacity-60: rgba(#121212, 0.6);
$black-opacity-40: rgba(#121212, 0.5);

$white-solid: #FFFFFF;
$white-opacity-65: rgba(#FFFFFF, 0.65);
$white-opacity-50: rgba(#FFFFFF, 0.5);

$green-1: #369280;
$green-2: #93B2A5;
$green-3: #CAE8E2;
$green-4: #eef0ef;

$grey-1: #F4F4F4;
$grey-2: #C2C6D3;
$grey-3: #D5D8E3;
$grey-4: #999EAF;

$red-1: #FFCACA;
$red-2: #FF7272;

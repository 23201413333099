@import '../../../styles/mixins.scss';
@import '../../../styles/colors';

.order_timeline__timeline {
  @include reset-list;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-placed,
  &-assigned,
  &-picked {
    @include respond-to-base {
      --parent-height: 230px;
    }
  
    @include respond-to-sm {
      --parent-height: 250px;
    }
  }

  &-delivered {
    @include respond-to-base {
      --parent-height: 190px;
    }
  
    @include respond-to-sm {
      --parent-height: 210px;
    }
  }
  
  &::before {
    @include pseudo-icon;
    top: 25px;
    left: 11px;
    width: 1px;
    height: var(--parent-height);
    border-right: 1px dashed $grey-2;
  }
}

.order_timeline__step {
  position: relative;
  display: flex;
  flex-direction: column;
  
  
  padding-left: 42px;

  font-weight: 500;

  @include respond-to-base {
    gap: 8px;
    min-height: 60px;
    font-size: 16px;
  }

  @include respond-to-sm {
    gap: 13px;
    min-height: 66px;
    font-size: 18px;
  }

  &::before {
    @include pseudo-icon;
    background-image: url(icon-step-todo.svg);
  }

  &-active {
    &::before {
      @include pseudo-icon;
      background-image: url(icon-step-complete.svg);
    }
  }

  &-picked,
  &-delivered {
    min-height: 97px;
  }
}

.order_timeline__hint {
  position: relative;
  padding: 7px 11px 7px 34px;
  font-weight: 500;
  background-color: $grey-1;
  border-radius: 100px;

  @include respond-to-base {
    margin-bottom: 36px;
    font-size: 12px;
  }

  @include respond-to-sm {
    margin-bottom: 42px;
    font-size: 14px;
  }

  &::before {
    @include pseudo-icon;
    top: 6px;
    left: 11px;
    width: 18px;
    height: 18px;
  }

  &-placed {
    &::before {
      background-image: url(icon-clock.svg);
    }
  }

  &-assigned {
    &::before {
      background-image: url(icon-truck.svg);
    }
  }

  &-picked {
    &::before {
      background-image: url(icon-route.svg);
    }
  }

  &-delivered {
    background-color: $green-3;
    &::before {
      background-image: url(icon-delivered.svg);
    }
  }
}

.order_timeline__step-pickup_address,
.order_timeline__step-delivery_address {
  position: relative;
  padding-left: 24px;
  font-weight: 400;

  @include respond-to-base {
    font-size: 14px;
  }

  @include respond-to-sm {
    font-size: 16px;
  }

  &::before {
    @include pseudo-icon;
    width: 20px;
    height: 20px;
  }
}

.order_timeline__step-pickup_address {
  &::before {
    background-image: url(icon-box.svg);
  }

  &-complete {
    &::before {
      background-image: url(icon-dot-2.svg);
    }  
  }
}

.order_timeline__step-delivery_address {
  &::before {
    background-image: url(icon-dot-1.svg);
  }

  &-active {
    &::before {
      background-image: url(icon-box.svg);
    }
  }
}


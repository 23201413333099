@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';

.user_order_create__login__section {
  position: relative;
  margin-bottom: 24px;
  overflow: hidden;

  @include respond-to-base {
    width: 370px;
  }

  @include respond-to-sm {
    width: 1024px;
  }
}

.user_order_create__login__container {
  display: flex;
  width: 200%;
  transition: transform 0.4s ease-in-out;

  @include respond-to-base {
    margin-bottom: 20px;
  }

  @include respond-to-sm {
    margin-bottom: 24px;
  }
}

.user_order_create__login__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  width: 50%;
  margin-bottom: 24px;

  @include respond-to-base {
    margin-bottom: 20px;
  }

  @include respond-to-sm {
    margin-bottom: 24px;
    padding: 26px 24px 26px 24px;
  }
}

.user_order_create__login__google,
.user_order_create__login__otp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_order_create__login__title-google {
  margin: 0;
  margin-bottom: 26px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.user_order_create__login__title-otp {
  margin: 0;
  margin-bottom: 26px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.user_order_create__login__button_otp {
  @include button-standard;

  @include respond-to-base {
    width: 370px;
    height: 58px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
  }
}

.user_order_create__login__button_google {
  @include button-border;
  position: relative;
  padding-left: 90px;
  
  @include respond-to-base {
    width: 370px;
    height: 58px;
    padding: 19px 40px 19px 40px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
    padding: 24px 40px 24px 90px;
  }

  &::before {
    @include pseudo-icon;
    background-image: url(icon-google.svg);

    @include respond-to-base {
      top: 16px;
      left: 76px;
    }

    @include respond-to-sm {
      top: 22px;
      left: 130px;
    }
  }
}

.user_order_create__login__or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

  @include respond-to-base {
    width: 370px;
  }

  @include respond-to-sm {
    width: 438px;
  }

  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    margin: 0 10px;
    border-top: 1px solid $black-opacity-40;
  }

  &::before {
    margin-left: 10px;
  }

  &::after {
    margin-right: 10px;
  }
}

.user_order_create__login__label {
  position: relative;

  &::after {
    background-image: url(icon-mail.svg);

    @include respond-to-base {
      top: 17px;
      left: 327px;
    }

    @include respond-to-sm {
      @include pseudo-icon;
      top: 22px;
      left: 390px;
    }
  }
}

.user_order_create__login__input {
  @include input-standard;
  margin-bottom: 13px;

  @include respond-to-base {
    width: 370px;
    height: 58px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
  }
}

.user_order_create__login__otp_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 50%;
}

.user_order_create__login__otp_button_back {
  @include button-link;
  position: relative;
  padding-left: 30px;
  align-self: flex-start;
  height: 0px;

  @include respond-to-base {
    top: -46px;
    left: -7px;
  }

  @include respond-to-sm {
    top: -86px;
    left: 10px;
  }

  &::before {
    @include pseudo-icon;
    top: -1px;
    left: 4px;
    background-image: url(icon-arrow-left.svg);
  }
}

.user_order_create__login__otp_title {
  margin: 0;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 500;

  @include respond-to-base {
    text-align: center;
  }
}

.user_order_create__login__otp_input {
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;

  @include respond-to-base {
    gap: 8px;
  }

  @include respond-to-sm {
    gap: 12px;
  }
}

.user_order_create__login__otp_input_cell {
  @include input-standard;

  @include respond-to-base {
    width: 52px;
  }

  @include respond-to-sm {
    width: 63px;
  }
}

.user_order_create__login__otp_button_signin {
  @include button-standard;
  margin-bottom: 12px;

  @include respond-to-base {
    width: 370px;
    height: 58px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
  }
}

.user_order_create__login__otp_button_resend {
  @include button-link;
}

.slide-left {
  transform: translateX(-50%);
}

.slide-right {
  transform: translateX(0);
}

.user_order_create__login__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  padding: 26px 24px 26px 24px;

  background-color: $white-opacity-50;
  border-radius: 12px;
}

.user_order_create__login__success_title {
  position: relative;
  margin: 0;
  margin-bottom: 12px;
  padding-top: 42px;
  font-size: 48px;
  font-weight: 600;

  &::before {
    @include pseudo-icon;
    top: -8px;
    left: 179px;
    width: 42px;
    height: 42px;
    background-image: url(icon-checkmark-big.svg);
  }
}

.user_order_create__login__user {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.user_order_create__login__user_text,
.user_order_create__login__user_name {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.user_order_create__login__add_payment {
  @include button-standard;

  @include respond-to-base {
    width: 370px;
    height: 58px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
  }
}

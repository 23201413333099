@import '../../../styles/mixins.scss';
@import '../../../styles/colors';

.driver_dashboard__section {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 18px;
}

.driver_dashboard__orders {
	@include reset-list;
	display: flex;
	flex-direction: column;
}


.driver_dashboard__order_wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-top: 24px;
	padding-bottom: 24px;

	border-bottom: 1px solid $grey-3;
}

.driver_dashboard__heading {
  display: flex;
  gap: 14px;

  @include respond-to-base {
    justify-content: space-between;
  }

  @include respond-to-sm {
    justify-content: flex-start;
		align-items: center;
		gap: 24px;
  }
}

.driver_dashboard__title {
  margin: 0;
  padding: 0;
  font-weight: 500;

  @include respond-to-base {
    font-size: 24px;
  }

  @include respond-to-sm {
    font-size: 36px;
  }
}

.driver_dashboard__button-assign {
  @include button-border;

	@include respond-to-sm {
		padding: 16px;
	}
}

.driver_dashboard__order_content {
  display: flex;
  gap: 24px;
	min-height: 520px;

  @include respond-to-base {
    flex-direction: column;
  }

  @include respond-to-sm {
    flex-direction: row;
  }
}

.driver_dashboard__order {
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  @include respond-to-base {
    width: 338px;
  }

  @include respond-to-sm {
    width: 398px;
  }
}

.driver_dashboard__order_tabs {
  display: flex;
}

.driver_dashboard__order_tab {
  @include button-link;
  
  padding-top: 0;
  padding-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  color: $grey-4;
  border-bottom: 1px solid $grey-2;

  @include respond-to-base {
    width: 170px;
  }

  @include respond-to-sm {
    width: 233px;
  }

  &-active {
    color: $black-solid;
    border-bottom: 1px solid $black-solid;
    cursor: auto;

    &:hover {
      color: $black-solid;
    }
  }
}


$breakpoint-base: 0px;
$breakpoint-xs: 430px;
$breakpoint-sm: 768px; 
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1700px;

@mixin respond-to-base {
  @media (min-width: #{$breakpoint-base}) {
    @content;
  }
}

@mixin respond-to-xs {
  @media (min-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin respond-to-sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin respond-to-md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin respond-to-lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin respond-to-xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';

.user_order_create__complete__section {
  position: relative;
  margin-bottom: 24px;
  overflow: hidden;
}

.user_order_create__complete__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include respond-to-base {
    gap: 13px;
    padding: 13px 0 26px 0;
    font-size: 24px;
    text-align: center;
  }

  @include respond-to-sm {
    gap: 24px;
    padding: 26px 24px 26px 24px;
    font-size: 48px;
    text-align: left;
  }
}

.user_order_create__complete__title {
  position: relative;
  margin: 0;
  padding-top: 42px;
  font-weight: 600;

  @include respond-to-base {
    font-size: 24px;
    text-align: center;
  }

  @include respond-to-sm {
    font-size: 48px;
    text-align: left;
  }

  &::before {
    @include pseudo-icon;
    width: 42px;
    height: 42px;
    background-image: url(icon-checkmark-big.svg);

    @include respond-to-base {
      top: -8px;
      left: 57px;
    }

    @include respond-to-sm {
      top: -8px;
      left: 147px;
    }
  }
}

.user_order_create__complete__text_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_order_create__complete__text {
  margin: 0;
  font-size: 16px;
  font-weight: 400;

  @include respond-to-base {
    text-align: center;
  }

  @include respond-to-sm {
    text-align: left;
  }
}

.user_order_create__complete__button {
  @include button-standard;
  width: 438px;
}

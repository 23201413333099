@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';

.user_order_create__summary__section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 17px;

  @include respond-to-base {
    padding-top: 20px;
  }

  @include respond-to-sm {
    padding-top: 24px;
    width: 1024px;
  }
}

.user_order_create__summary__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  background-color: $white-opacity-50;
  border-radius: 12px;

  @include respond-to-base {
    gap: 20px;
    padding: 16px;
    margin-bottom: 20px;
  }

  @include respond-to-sm {
    gap: 24px;
    padding: 26px 24px 26px 24px;
    margin-bottom: 24px; 
  }
}

.user_order_create__summary__heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user_order_create__summary__title {
  margin: 0;

  text-align: center;

  @include respond-to-base {
    font-size: 24px;
    font-weight: 500;
  }

  @include respond-to-sm {
    font-size: 36px;
    font-weight: 600;
  }

  &-complete {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    text-align: start;
  }
}

.user_order_create__summary__date {
  font-size: 14px;
  font-weight: 400;
  color: $black-opacity-60;
}

.user_order_create__summary__edit-border{
  @include button-border;
  position: relative;
  font-size: 16px;

  @include respond-to-sm {
    padding: 14px 20px 14px 50px;
  }

  &::before {
    @include pseudo-icon;
    top: 15px;
    left: 19px;
    width: 18px;
    height: 18px;
    background-image: url(icon-pen.svg);
  }
}

.user_order_create__summary__edit-link{
  @include button-link;
  position: relative;
  padding-left: 25px;
  font-size: 12px;

  &::before {
    @include pseudo-icon;
    top: -1px;
    left: 2px;
    width: 18px;
    height: 18px;
    background-image: url(icon-arrow-left.svg);
  }
}

.user_order_create__summary__status {
  position: relative;
  margin: 0;
  padding: 7px 10px 7px 32px;

  font-size: 14px;
  font-weight: 500;

  background-color: $grey-3;
  border-radius: 50px;

  &::before {
    @include pseudo-icon;
    top: 7px;
    left: 10px;
    width: 18px;
    height: 18px;
    background-image: url(icon-clock.svg);
  }
}

.user_order_create__summary__summary {
  display: flex;
  justify-content: space-between;

  @include respond-to-base {
    flex-direction: column;
    gap: 20px;
  }

  @include respond-to-sm {
    flex-direction: row;
    gap: 0;
  }
}

.user_order_create__summary__map_wrapper {
  position: relative;
  border-radius: 12px;

  @include respond-to-base {
    width: 100%;
    height: 310px;
  }

  @include respond-to-sm {
    width: 48%;
    height: 468px;
  }
}

.user_order_create__summary__details {
  display: flex;
  flex-direction: column;
  
  align-items: flex-start;

  @include respond-to-base {
    width: 100%;
    gap: 20px;
  }

  @include respond-to-sm {
    width: 48%;
    gap: 26px;
  }
}


.user_order_create__summary__details_detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.user_order_create__summary__detail_title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 600;

  @include respond-to-base {
    font-size: 16px;
  }

  @include respond-to-sm {
    font-size: 18px;
  }
}

.user_order_create__summary__detail_data  {
  margin: 0;
  font-size: 16px;
  font-weight: 400;

  @include respond-to-base {
    font-size: 14px;
  }

  @include respond-to-sm {
    font-size: 16px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.user_order_create__summary__detail_photos {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;
}

.user_order_create__summary__detail_photo {
  width: 126px;
  height: 98px;
  border-radius: 12px;
  object-fit: cover;
}

.user_order_create__summary__agreements {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.user_order_create__summary__agreements_confirm-label,
.user_order_create__summary__agreements_terms-label {
  position: relative;
  display: inline-block;

  font-size: 12px;
  font-weight: 400;

  cursor: pointer;

  @include respond-to-base {
    margin-right: 0;
    padding-left: 36px;
  }

  @include respond-to-sm {
    margin-right: 10px;
    padding-left: 40px;
  }

  &::before {
    @include pseudo-icon;
    top: -4px;
    left: -1px;
    width: 28px;
    height: 28px;
    background-image: url(icon-checkbox-unchecked.svg);
  }
}

.user_order_create__summary__agreements_confirm-input,
.user_order_create__summary__agreements_terms-input {
  appearance: none;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.user_order_create__summary__agreements_confirm-input:checked + .user_order_create__summary__agreements_confirm-label::before,
.user_order_create__summary__agreements_terms-input:checked + .user_order_create__summary__agreements_terms-label::before {
  background-image: url(icon-checkbox-checked.svg);
}

.user_order_create__summary__agreements__rate_section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid $grey-2;

  @include respond-to-base {
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
  }

  @include respond-to-sm {
    flex-direction: row;
    gap: 0;
    padding-top: 24px;
  }
}

.user_order_create__summary__agreements__rate_wrapper {
  display: flex;

  @include respond-to-base {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include respond-to-sm {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  &-confirmed {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }
}

.user_order_create__summary__agreements__rate_title {
  font-weight: 600;
  margin-right: 14px;

  @include respond-to-base {
    font-size: 14px;
  }

  @include respond-to-sm {
    font-size: 20px;
  }

  &-confirmed {
    font-size: 18px;
  }
}

.user_order_create__summary__agreements__rate_amount {
  font-weight: 600;

  @include respond-to-base {
    font-size: 32px;
  }

  @include respond-to-sm {
    font-size: 48px;
  }

  &-confirmed {
    font-size: 24px;
  }
}

.user_order_create__summary__agreements__rate_button {
  @include button-standard;
  align-self: center;
}
.slide-enter {
  transform: translateY(-100%);
  opacity: 0;
  display: block; /* Ensure it's displayed */
  position: fixed; /* Prevent content shifts */
  top: 0;
  left: 0;
  width: 100%;
}

.slide-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}
.slide-exit {
  transform: translateY(0);
  opacity: 1;
}
.slide-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}

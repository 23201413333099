@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';

.user_order_create__payment_setup__section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  overflow: hidden;
}

.user_order_create__payment_setup__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 370px;
  margin-bottom: 24px;
  padding: 26px 24px 26px 24px;

  height: auto;
  background-color: $white-opacity-50;
  border-radius: 12px;
}

.user_order_create__payment_setup__title {
  margin: 0;
  margin-bottom: 8px;

  font-size: 24px;
  font-weight: 500;
}

.user_order_create__payment_setup__text {
  margin: 0;
  margin-bottom: 24px;

  font-size: 16px;
  font-weight: 400;

  @include respond-to-base {
    text-align: center;
  }

  @include respond-to-sm {
    text-align: left;
  }
}

.user_order_create__payment_setup__submit {
  @include button-standard;
  width: 150px;
  background-color: white;
  color: black;
  margin-top: 24px;
}

.user_order_create__payment_setup__rate {
  margin: 0;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
}

.user_order_create__payment_setup__amount {
  font-size: 24px;
  font-weight: 600;
}

.user_order_create__payment_setup__reuse {
  max-width: 350px;
  margin: 0;
  margin-bottom: 24px;
  text-align: center;
}

.user_order_create__payment_setup__submit-reuse {
  @include button-standard;

  @include respond-to-sm {
    width: 100%;
  }
}

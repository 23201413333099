@import '../../../styles/mixins.scss';
@import '../../../styles/colors';

.login_page__section {
  @include container;
  display: flex;
  padding-top: 21px;

  @include respond-to-base {
    flex-direction: column-reverse;
    gap: 32px;
  }

  @include respond-to-sm {
    flex-direction: row;
    gap: 120px;
  }
}

.login_page__wrapper-login {
  display: flex;
  flex-direction: column;

  @include respond-to-base {
    width: 370px;
  }

  @include respond-to-sm {
    width: 450px;
  }
}

.login_page__title {
  font-size: 48px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;

  @include respond-to-base {
    font-size: 29px;
    text-align: center;
    margin-bottom: 16px;
  }

  @include respond-to-sm {
    font-size: 48px;
    text-align: left;
    margin-bottom: 10px;
  }
}

.login_page__subtitle {
  font-weight: 500;
  margin: 0;
  font-size: 24px;
  text-align: left;
  margin-bottom: 46px;
}

.login_page__wrapper-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_page__image {
  border-radius: 12px;

  @include respond-to-base {
    width: 240px;
    height: 240px;
  }

  @include respond-to-sm {
    width: 779px;
    height: auto;
  }
}

.login_page__login {
  position: relative;
  overflow: hidden;
}

.login_page__container {
  display: flex;
  width: 200%;
  transition: transform 0.4s ease-in-out;
}

.login_page__login__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  width: 50%;


  @include respond-to-base {
    margin-bottom: 20px;
    padding: 24px 16px;
    background-color: $white-opacity-50;
    border-radius: 12px;
  }

  @include respond-to-sm {
    padding: 0;
    background-color: transparent;
  }
}

.login_page__login__google,
.login_page__login__otp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login_page__login__title-google {
  margin: 0;
  margin-bottom: 26px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.login_page__login__title-otp {
  margin: 0;
  margin-bottom: 26px;
  font-weight: 600;

  @include respond-to-base {
    font-size: 16px;
    text-align: center;
  }

  @include respond-to-sm {
    font-size: 18px;
    text-align: left;
  }
}

.login_page__login__button_otp {
  @include button-standard;

  @include respond-to-base {
    width: 338px;
    height: 58px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
  }
}

.login_page__login__button_google {
  @include button-border;
  position: relative;
  padding-left: 90px;
  
  @include respond-to-base {
    width: 338px;
    height: 58px;
    padding: 19px 40px 19px 60px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
    padding: 24px 40px 24px 90px;
  }

  &::before {
    @include pseudo-icon;
    background-image: url(icon-google.svg);

    @include respond-to-base {
      top: 16px;
      left: 76px;
    }

    @include respond-to-sm {
      top: 21px;
      left: 129px;
    }
  }
}

.login_page__login__or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;

  @include respond-to-base {
    width: 338px;
  }

  @include respond-to-sm {
    width: 438px;
  }

  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    margin: 0 10px;
    border-top: 1px solid $black-opacity-40;
  }

  &::before {
    margin-left: 10px;
  }

  &::after {
    margin-right: 10px;
  }
}

.login_page__login__label {
  position: relative;

  &::after {
    background-image: url(icon-mail.svg);

    @include respond-to-base {
      top: 17px;
      left: 327px;
    }

    @include respond-to-sm {
      @include pseudo-icon;
      top: 22px;
      left: 390px;
    }
  }
}

.login_page__login__input {
  @include input-standard;
  margin-bottom: 13px;

  @include respond-to-base {
    width: 338px;
    height: 58px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
  }
}

.login_page__login__otp_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 50%;


@include respond-to-base {
  padding: 24px 16px;
  background-color: $white-opacity-50;
  border-radius: 12px;
}

@include respond-to-sm {
  padding: 0;
  background-color: transparent;
}
}

.login_page__login__otp_button_back {
  @include button-link;
  position: relative;
  padding-left: 30px;
  align-self: flex-start;
  height: 0px;

  @include respond-to-base {
    top: -46px;
    left: -7px;
    font-size: 16px;
  }

  @include respond-to-sm {
    top: -52px;
    left: -9px;
    font-size: 18px;
  }

  &::before {
    @include pseudo-icon;
    top: -1px;
    left: 4px;
    background-image: url(icon-arrow-left.svg);
  }
}

.login_page__login__otp_title {
  margin: 0;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 500;

  @include respond-to-base {
    font-size: 16px;
    text-align: center;
  }

  @include respond-to-sm {
    font-size: 18px;
    text-align: left;
  }
}

.login_page__login__otp_input {
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;

  @include respond-to-base {
    gap: 8px;
  }

  @include respond-to-sm {
    gap: 12px;
  }
}

.login_page__login__otp_input_cell {
  @include input-standard;

  @include respond-to-base {
    width: 48px;
  }

  @include respond-to-sm {
    width: 63px;
  }
}

.login_page__login__otp_button_signin {
  @include button-standard;
  margin-bottom: 12px;

  @include respond-to-base {
    width: 338px;
    height: 58px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
  }
}

.login_page__login__otp_button_resend {
  @include button-link;

  @include respond-to-base {
    font-size: 16px;
  }

  @include respond-to-sm {
    font-size: 18px;
  }
}

.slide-left {
  transform: translateX(-50%);
}

.slide-right {
  transform: translateX(0);
}

.login_page__login__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  padding: 26px 24px 26px 24px;

  background-color: $white-opacity-50;
  border-radius: 12px;
}

.login_page__login__success_title {
  position: relative;
  margin: 0;
  margin-bottom: 12px;
  padding-top: 42px;
  font-size: 48px;
  font-weight: 600;

  &::before {
    @include pseudo-icon;
    top: -8px;
    left: 179px;
    width: 42px;
    height: 42px;
    background-image: url(icon-checkmark-big.svg);
  }
}

.login_page__login__user {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.login_page__login__user_text,
.login_page__login__user_name {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.login_page__login__add_payment {
  @include button-standard;

  @include respond-to-base {
    width: 370px;
    height: 58px;
  }

  @include respond-to-sm {
    width: 438px;
    height: 70px;
  }
}

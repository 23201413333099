@import '../../../../../../styles/mixins';
@import '../../../../../../styles/colors';

.user_order_create__photos__section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
  margin-bottom: 24px;
  border-top: 1px solid $grey-2;

  @include respond-to-base {
    padding-top: 20px;
    margin-bottom: 20px;
  }

  @include respond-to-sm {
    padding-top: 24px;
    margin-bottom: 24px;
  }
}

.user_order_create__photos__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
  padding: 26px 24px 26px 24px;

  background-color: $white-opacity-50;
  border-radius: 12px;

  @include respond-to-base {
    gap: 10px;
    padding: 16px;
  }

  @include respond-to-sm {
    gap: 26px;
    padding: 26px 24px 26px 24px;
  }
}

.user_order_create__photos__title {
  margin: 0;
  font-weight: 600;

  @include respond-to-base {
    font-size: 16px;
  }

  @include respond-to-sm {
    font-size: 18px;
  }
}

.user_order_create__photos__photos {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;
}


.user_order_create__photos__preview {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include respond-to-base {
    gap: 10px;
  }

  @include respond-to-sm {
    gap: 24px;
  }
}

.user_order_create__photos__photo_wrapper {
  position: relative;
}

.user_order_create__photos__photo {
  border-radius: 12px;
  object-fit: cover;

  @include respond-to-base {
    width: 100%;
    height: auto;
  }

  @include respond-to-sm {
    width: 226px;
    height: 148px;
  }
}

.user_order_create__photos__photo_delete {
  @include button-reset;

  position: absolute;
  width: 44px;
  height: 44px;
  
  background-color: $white-opacity-50;
  background-image: url(icon-delete.svg);
  background-size: initial;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  border-radius: 50%;

  cursor: pointer;

  @include respond-to-base {
    top: 20px;
    left: 270px;
  }

  @include respond-to-sm {
    top: 10px;
    left: 170px;
  }

  &:hover {
    background-color: $green-3;
    box-shadow: 0px 10px 12px rgba(83, 84, 90, 0.01), 
                0px 8px 10px rgba(83, 84, 90, 0.05), 
                0px 4px 6px rgba(83, 84, 90, 0.09), 
                0px 1px 4px rgba(83, 84, 90, 0.1);
    transition: all 0.2s ease-in-out;
  }

  &:active {
    transform: scale(0.95); 
    transition: all 0.1s ease-in-out;
  }
}

.user_order_create__photos__add_label {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;

  font-weight: 400;
  
  background-color: $white-solid;
  border: 1px dashed $grey-2;
  border-radius: 12px;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.1s ease;
  cursor: pointer;

  @include respond-to-base {
    width: 162px;
    height: 108px;

    font-size: 12px;
  }

  @include respond-to-sm {
    width: 226px;
    height: 148px;

    font-size: 14px;
  }

  &::before {
    @include pseudo-icon;
    background-image: url(icon-add.svg);
    @include respond-to-base {
      top: 29px;
      left: 70px;
    }

    @include respond-to-sm {
      top: 44px;
      left: 100px;
    }
  }

  &:active {
    background-color: darken($white-solid, 5%);
    border-color: darken($grey-2, 20%);
    transform: scale(0.98);
  }
}

.user_order_create__photos__add_input {
  display: none;
}

.user_order_create__photos__details {
  width: 100%;
  background-color: $white-opacity-50;
  border-radius: 12px;
  padding: 26px 24px 26px 24px;
}

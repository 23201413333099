@import './breakpoints.scss';

@mixin container {
  margin: 0 auto;

  @include respond-to-base {
    width: 402px;
    padding-right: 16px;
    padding-left: 16px;
  }

  @include respond-to-sm {
    width: 1280px;
    padding-right: 85px;
    padding-left: 85px;
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-anchor {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

@mixin button-standard {
  padding: 24px 40px 24px 40px;
  background-color: $black-solid;
  color: $white-solid;
  border-radius: 12px;

  font-family: Inter, sans-serif;
  letter-spacing: -1%;
  font-weight: 500;
  border: none;

  transition: background-color 0.3s ease, transform 0.2s ease;

  cursor: pointer;

  &:hover {
    background: $black-opacity-60;
  }

  &:active {
    background-color: $black-solid;
  }

  &:disabled {
    color: $grey-4;
    background-color: $grey-3;
    cursor: auto;
  }

  @include respond-to-base {
    width: 100%;
    max-height: 58px;
    padding: 19px 40px 19px 40px;
    font-size: 16px;
  }

  @include respond-to-sm {
    width: auto;
    max-height: 70px;
    padding: 24px 40px 24px 40px;
    font-size: 18px;
  }
}

@mixin button-border {
  padding: 24px 40px 24px 40px;
  font-size: 18px;
  font-weight: 500;
  color: $black-solid;
  background-color: transparent;
  border: 1px solid $black-solid;
  border-radius: 8px;
  cursor: pointer;
    
  transition: color 0.2s ease;

  @include respond-to-base {
    width: 100%;
    max-height: 58px;
    padding: 19px 40px 19px 40px;
    font-size: 16px;
  }

  @include respond-to-sm {
    width: auto;
    max-height: 70px;
    padding: 24px 40px 24px 40px;
    font-size: 18px;
  }

  &:hover {
    color: $black-opacity-60;
    border: 1px solid $black-opacity-60;
  }

  &:active {
    color: $black-solid;
    border: 1px solid $black-solid;
  }
}

@mixin button-link {
  font-size: 18px;
  font-weight: 600;

  color: $black-solid;

  border: none;
  background-color: transparent;

  cursor: pointer;

  &:hover{
    color: $black-opacity-60;
  }

  &:active{
    color: $black-solid;
  }
}

@mixin button-reset {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin input-standard {
  width: 228px;
  height: 70px;
  padding: 25px 24px 26px 24px;
  background: $white-solid;
  border: 1px solid $grey-2;
  border-radius: 12px;
  font-family: inherit;
  font-size: 16px;

  @include respond-to-base {
    padding: 20px 16px 20px 16px;
  }

  @include respond-to-sm {
    padding: 25px 24px 26px 24px;
  }

  &:focus {
    border: 1px solid $black-solid;
    outline: none;
  }
}

@mixin pseudo-icon {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/colors.scss";

.stripe__button {
	@include button-standard;
	margin-top: 14px;

	@include respond-to-base {
    width: 100%;
  }

  @include respond-to-sm {
		width: 292px;
  }
}

.stripe__error {
	margin-top: 14px;
	padding: 7px 14px;
	font-size: 14px;
	font-weight: 500;

	text-align: center;

	background-color: $red-1;
	border-radius: 100px;
}